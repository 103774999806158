.avater {
    background: linear-gradient(
        45deg, #000 0%, #15F4EE 100%) !important;
    color: white;
    padding: 0.5rem;
    /* font-family: sans-serif; */
    border-radius: 0.3rem;
    cursor: pointer;
    margin-top: 1rem;
  }
  .referralCodeEdit{
      display: flex;
      justify-content: space-between;
  }
  .referralCodeEditbutton {
      color:white;
      background: transparent;
      border: none;
  }
  .inputEditButton{
      display: flex;
      justify-content: space-between;
  }
.opacity-50 {
    opacity: .5;
}
.tracking-widest {
    letter-spacing: .1em;
}
.uppercase {
    text-transform: uppercase;
}
.text-3xs {
    font-size: .65rem;
}
.font-mono {
    font-family: pixelfont,Helvetica Neue,Helvetica,Arial,sans;
}
.text-center {
    text-align: center;
}
.mt-3 {
    margin-top: 0.75rem;
}


.tracking-wider {
    letter-spacing: .05em;
}
.uppercase {
    text-transform: uppercase;
}
.font-400 {
    font-weight: 400;
}
.text-2xs {
    font-size: .7rem;
}
.text-center {
    text-align: center;
}
.pb-2 {
    padding-bottom: 0.5rem;
}
.-mt-1 {
    margin-top: -0.25rem;
}

.zoom:hover {
    -ms-transform: scale(1.3); /* IE 9 */
    -webkit-transform: scale(1.3); /* Safari 3-8 */
    transform: scale(1.3); 
  }



  /*  */

  .py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.border-opacity-10 {
    --tw-border-opacity: 0.1;
}
.border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0,0,0,var(--tw-border-opacity));
}
.border-b {
    border-bottom-width: 1px;
}
.items-center {
    align-items: center;
}
.cursor-pointer {
    cursor: pointer;
}
.w-full {
    width: 100%;
}
.flex {
    display: flex;
}

.text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217,119,6,var(--tw-text-opacity));
}
.text-black {
    --tw-text-opacity: 1;
    color: rgba(0,0,0,var(--tw-text-opacity));
}
.uppercase {
    text-transform: uppercase;
}
.font-600 {
    font-weight: 600;
}
.text-xs {
    font-size: .75rem;
    line-height: 1rem;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.relative {
    position: relative;
}

.grid-cols-1 {
    grid-template-columns: repeat(1,minmax(0,1fr));
}
.w-7 {
    width: 1.75rem;
}
.grid {
    display: grid;
}
.mr-2 {
    margin-right: 0.5rem;
}


.goldentext {
    background: linear-gradient(-45deg,#ffd580,#f0a925,#ffe56f,#f6b547);
    background-size: 200% 200%;
    -webkit-animation: gradient 2s linear infinite;
    animation: gradient 2s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pr-1 {
    padding-right: 0.25rem;
}
.ml-auto {
    margin-left: auto;
}
.relative {
    position: relative;
}




/* seafch */

.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.border-opacity-10 {
    --tw-border-opacity: 0.1;
}
.border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0,0,0,var(--tw-border-opacity));
}
.border-b {
    border-bottom-width: 1px;
}
.items-center {
    align-items: center;
}
.w-full {
    width: 100%;
}
.flex {
    display: flex;
}
.relative {
    position: relative;
}

.text-black {
    --tw-text-opacity: 1;
    color: rgba(0,0,0,var(--tw-text-opacity));
}
.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}
.bg-transparent {
    background-color: transparent;
}
.border-none {
    border-style: none;
}
.border-0 {
    border-width: 0;
}
.w-full {
    width: 100%;
}
.h-full {
    height: 100%;
}
.z-10 {
    z-index: 10;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  /* toggle */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border-width: 0;
}
.duration-300 {
    transition-duration: .3s;
}
.shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
}
.shadow-inner, .shadow-none {
    box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
}
.bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253,230,138,var(--tw-bg-opacity));
}
.rounded-full {
    border-radius: 9999px;
}
.w-10 {
    width: 2.5rem;
}
.h-4 {
    height: 1rem;
}

.dot {
    background: linear-gradient(-45deg,#fff5e1,#efb346,#ffeda0,#f6b547);
    background-size: 200% 200%;
    -webkit-animation: gradient 8s ease infinite;
    animation: gradient 8s ease infinite;
    transform: translateX(100%);
}

.goldentext {
  /*   background: linear-gradient(-45deg,#ffd580,#f0a925,#ffe56f,#f6b547); */
    background-size: 200% 200%;
   
}

.t{
    font-size: 36px;
    font-weight: 800;
}

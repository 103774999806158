.icon-bar {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
  }
  .icon-bar a i {
    color:#f879b6;
  }
  
  .icon-bar a:hover {
    background-color: #000;
  }
  
  .facebook {
    background: transparent;
    color: #f879b6;
  }
  
  .twitter {
    background: transparent;
    color: #f879b6;
  }
  
  .google {
    background: transparent;
    color: #f879b6;
  }
  
  .linkedin {
    background: transparent;
    color: white;
  }
  
  .youtube {
    background: transparent;
    color: #f879b6;
  }
  
  /* .content {
    margin-left: 75px;
    font-size: 30px;
  } */

  @media only screen and (max-width: 600px) {
    .icon-bar {
      
      position: relative;
      top:0;
      display: inline-flex;
      margin-top: 20px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    
  }

  @media only screen and (max-width: 600px) {
    .icon-bar a {
      display: block;
      text-align: center;
      padding-right: 16px;
    padding-left: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
      transition: all 0.3s ease;
      color: white;
      font-size: 20px;
    }
    
  }


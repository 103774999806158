.deepak {
   color:  #74fffa !important;
}

.abhimanyu{
    color:white !important
}

.linkactive:hover{
    color: #9982ed !important;
    
}
.linkactive{
    color: white;
}
a:not([href]):not([tabindex]){
    color: white !important;
}
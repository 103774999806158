.laptop-wrapper-2 {
    width: 100%;
    min-height: 450px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
}

@media only screen and (min-width: 960px) {
    .iframewidthheight{
        width: 480px;
        height: 600px;
    }
    
}
@media only screen and (min-width: 1340px) {
    .iframewidthheight{
        width: 800px;
        height: 600px;
    }
}

@media only screen and (max-width: 600px) {
    .iframewidthheight{
        /* width: 800px; */
        height: 550px;
    }
    .page-ath-gfx{
        display: flex !important;
    }
    .page-ath-wrap{
        flex-direction: column !important;
    }
}




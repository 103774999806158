
body{
    background-color: #050613 !important;
    color:#fff !important;
}

.topbar {
    background: #050613 !important;
    position: relative;
    z-index: 3;
}

.navbar1{
    background: linear-gradient(-45deg, #9982ed 0%, #f879b6 100%) ;
}


.navbar1-menu>li>a {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 32px;
    padding: 12px 0;
    position: relative;
    color: #fff !important;
}

.btn-outline.btn-light {
    color: #fff !important;
    border-color: #fff !important;
}

 /* .card-token {
    background-image: linear-gradient(
        45deg, #050613 0%, #9982ed 100%) !important;
        box-shadow: 0 0px 26px 1px #9982ed !important;
    color: #fff;
} 

.card {
   background: #050613 !important;
    box-shadow: 0 0px 26px 1px #9982ed !important; 
  
} */

 .card-token {
   /*  background-image: linear-gradient(
        45deg, #050613 0%, #9982ed 100%) !important;
        box-shadow: 0 0px 26px 1px #9982ed !important; */
    color: #fff;
} 

.card {
    background: #050613 !important;
    /* box-shadow: 0 0px 26px 1px #9982ed !important; */
    -webkit-box-shadow:
    0px -10px 15px -7px  #f879b6 , /* top - THE RED SHADOW */
    0px  5px  15px  0px  #9982ed, /* bottom */
    5px  0px  15px  0px  #f879b6 , /* right */
   -5px  0px  15px  0px  #9982ed; /* left */
  /*  border-radius: 2pt;
   border: 1px solid #fff; */
 }

h1, h2, h4, h5 {
    color: #9982ed !important;
}

.btn-primary:disabled, .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    color: #fff;
    background: linear-gradient(-45deg, #9982ed 0%, #f879b6 100%) !important;
    border-color: #9982ed !important;
    box-shadow: 0 0px 10px 1px #15f4ee !important;
}

.btn-primary {
    background: linear-gradient(-45deg, #9982ed 0%, #f879b6 100%) !important;
        border-color: #9982ed !important;
        box-shadow: 0 0px 10px 1px #15f4ee!important;
}

.table thead th {
   
    color: #74fffa !important;
}

.token-info-list li {
    color: #74fffa !important;
}

.progress-percent {
    background: #74fffa !important;
}

.progress-percent:after {
    border: 3px solid #74fffa !important;
}

.user-thumb {
   
    background: #74fffa !important;
   
}

/* .navLinkActive {
    border-bottom: 2px solid #74fffa !important;
} */

.text-primary {
    color: #74fffa !important;
}

.pay-option-check:checked ~ label {
    border-color: #74fffa !important;
}

.pay-option-label:after {
  
    background: #74fffa !important;
 }

 h1 span, h2 span, h3 span, h4 span, h5 span, h6 span, p span {
    color: #74fffa !important;
}

.bonus-base {
   
    background: #74fffa !important;
}

.bonus-extra {
    background: #74fffa !important;
}

.token-overview-value {
    color: #74fffa !important;
}

p a {
    color: #74fffa !important;
}

.link-primary {
    color: #74fffa !important;
}

.schedule-bonus{
    color: #74fffa !important;
}

.data-item {
    background: none !important;
}

.data-head .data-col {
    color: #74fffa !important;
}

.card-title {
    color: #74fffa !important;
}

.data-col .sub {
   
    color: #fff !important;
}

.data-col .lead {
    
    color: #fff !important;
}

.badge-disabled {
    color: #495463;
    background: #050613 !important;
    border-color: #d2dde9;
}

.user-status {
    background: #050613 !important;
  
}

.bg-light {
    background: #050613 !important;
}

.user-links {
  
    background: #050613 !important;
}

a {
    
    color: #fff;
}

.modal-content {
   
    background-color: #050613 !important;
}

.nav-tabs-line .nav-link.active {
    cursor: default;
    color: #74fffa !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #050613 !important;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs-line .nav-link {
 
    color: #FFF !important;
    
}

.status-text {
    color: #fff !important;
}

.text-light {
    color: #fff !important;
}

.pay-option-label {
    width: 100%;
     background: none !important;
}

.note-light p, .note-light .note-text {
    color: #fff !important;
}

.footer-links li a {
    color: #fff !important;
}

.share-links li a {
  
    color: #fff !important;
   
}

.share-links li {
    color: #fff !important;
}


.dropdown-content {
   
    background: #050613 !important;
}

/* .navLinkActive {
    border-bottom: 2px solid #9982ed !important;
} */

.box{
   position: relative;
   width: 300px;
   height: 400px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #060c21;
}

/*  */
 .box:before
{
content: '';
position: absolute;
top: -2px;
left: -2px;
right: -2px;
bottom: -2px;
background: #fff;
z-index: -1;
} 

.box:after
{
content: '';
position: absolute;
top: -2px;
left: -2px;
right: -2px;
bottom: -2px;
background: #fff;
z-index: -2;
filter : blur(40px);
}

.box ::before,
.box ::after{
    background: linear-gradient(235deg, #89ff00,#060c21,#00bcd4 transparent)!important;
} 

/*  */
.content{
    padding: 20px;
    box-sizing: border-box;
    color: #fff !important;
}


/*  */

.token-info-list li span {
    color: #fff !important;
}

.table td .lead {
   color: #fff !important;
}

.table td .sub {
   
    color: #fff;
}

.table td .sub-s2 {
   
    color: #fff;
}

.dropdown-list li a {
    
    color: #fff;
}

.input-item-label {
   
    color: #fff;
}

.input-bordered {
   
    background: #3b3b3b;
    color: rgb(235 235 235 / 70%);
   
}


#glow {
    /* x     y   blur spread color */
  box-shadow: /* ie */
       0px -10px 15px -6px  rgba(255,000,000,0.7), /* top - THE RED SHADOW */
       0px  5px  15px  0px  rgba(000,000,000,0.3), /* bottom */
       5px  0px  15px  0px  rgba(000,000,000,0.3), /* right */
      -5px  0px  15px  0px  rgba(000,000,000,0.3); /* left */
  -webkit-box-shadow:
       0px -10px 15px -7px  #ff00ff, /* top - THE RED SHADOW */
       0px  5px  15px  0px  #9982ed, /* bottom */
       5px  0px  15px  0px  #ff00ff, /* right */
      -5px  0px  15px  0px  #9982ed; /* left */
  -moz-box-shadow:
       0px -9px  10px -8px  rgba(000,000,255,0.9), /* top - THE RED SHADOW */
       0px  5px  10px  0px  rgba(000,000,000,0.3), /* bottom */
       5px  0px  10px  0px  rgba(000,000,000,0.3), /* right */
      -5px  0px  10px  0px  rgba(000,000,000,0.3); /* left */
  }
  
  
  
  .gl {
  height: 300px;
  width: 300px;
  margin: 0px auto;
  border-radius: 2pt;
  border: 1px solid rgba(0,0,0,0.8);
  background-color: #fefefe;
  }
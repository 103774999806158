

@media only screen and (max-width: 600px) {
    .new{
        display: none;
    }
  }

  .mobileamount{
      margin-left: 20px;
  }

  @media only screen and (max-width: 600px) {
    .mobileamount{
        margin-left: 0px;
    }
  }


  @media only screen and (max-width: 600px) {
    .mobilecolsix{
        padding-left: 0px;
        padding-right: 0px;
    }
  }

  @media only screen and (max-width: 600px) {
    .clipfontsize{
       font-size: 9px;
    }
  }

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pay-option-check:checked ~ label {
  background: linear-gradient(
    45deg, #000 0%, #15F4EE 100%)  !important;
}

  
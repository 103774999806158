
    .whiteDiagonal{
    width:0;
    height:0;
    position:top;
    bottom:0;
    border-top:100px solid #050613;
    border-right:100vw solid transparent}
    .vivek{
        margin-bottom: 60px;
    }
    .kan {
        margin-top: 30px;
    }
        #myVideo {
            width: 100vw;
            height: 600px;
            object-fit: cover;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
        }

        .deepak {
            color: #fff !important;

        }

        .aman {
            color: #fff !important;

        }

        .dave {
            margin-top: -110px;
        }

        .paandu {
            font-size: 16px;
            font-weight: 600;
            text-transform: capitalize;
            background: linear-gradient(to bottom, #9982ed 0%, #f879b6 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: inline-block;
        }
        #chart {
            max-width: 850px;
            ;
        }

        #chart2 {
            max-width: 650px;
            margin: 35px auto;
        }
        .light {
            color: #7a839e !important;
        }

        .icon-bar {
            position: fixed;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          }
          
          .icon-bar a {
            display: block;
            text-align: center;
            padding: 16px;
            transition: all 0.3s ease;
            color: white;
            font-size: 20px;
          }
          
          .icon-bar a:hover {
            background-color: #000;
          }
          
          .facebook {
            background: #000;
            color: #FF00FF;
          }
          
          .twitter {
            background: #000;
            color: #FF00FF;
          }
          
          .google {
            background: #000;
            color: #FF00FF;
          }
          
          .linkedin {
            background: #000;
            color: #FF00FF;
          }
          
          .youtube {
            background: #000;
            color: #FF00FF;
          }
          
          .content {
            margin-left: 75px;
            font-size: 30px;
          }
          @media (max-width: 600px) {
            .whiteDiagonal {
              border-top:0px solid #050613;
              border-right:0vw solid transparent}
               
        }
          
          @media all and (max-width: 479px) {
              .icon-bar {display:none !important;}
          }
          /* .navbackground{
              background: #050613 !important;
          } */
          .mambawhite{
color:white !important
          }
          #best_feature_06 .feature_items .single-wrapper .single-items p{
              color:white !important
          }
.googlebutton{
  display: flex;
  justify-content: center;
  align-items: center;
}


.myClass li  button {
  background-color: transparent !important;
  border-color: none;
  color: white;
}

.modalfile{
  display: flex;
  justify-content: space-evenly;
}
.modalfirst{
  flex-grow: 1;
  padding:10px
}
.modalsecondpart{
  flex-grow: 1;
  padding:10px;
  align-self:center
}
@media all and (max-width: 479px) {
  .modalfile {
    display: flex;
    flex-direction: column;
  }
}
.language{
  margin-left: 40px;
}
@media all and (max-width: 479px) {
  .language {
    display: inline-flex;
   
  }
}

@media only screen and (max-width: 600px){
.navbar-nav li a{
  padding: 10px 15px !important;
  color: #ffffff !important;
  font-size: 15px;
  display: inline-block;
}
}

@media only screen and (max-width: 600px){
  .navbar {
   background: black !important;
  }
  .mobileFont{
    font-size: 13px;
  }
  }

  button:focus{
    outline: none;
    outline-color: none;
    outline-style: none;
    outline-width: 5px;
    cursor: pointer;
  }
  button{
    cursor: pointer;
  }
  #headerPopup{
    width:75%;
    margin:0 auto;
  }
  
  #headerPopup iframe{
    width:100%;
    margin:0 auto;
  }

  .dropdown-item:focus, .dropdown-item:hover{
    background-color:transparent !important;
  }


       
        
          
   
  
  .titleverifyno{
    font-weight:500;
    margin-top:20px;
    font-size:24px
  }

  .otp{
    display:inline-block;
    width:150px;
    height:50px;
    text-align:center;
  }
.amchartdiv{
    width: 100%;
    height: 190px;
}



@media only screen and (max-width: 600px) {
    .token-pay-amount{
        width: 160px !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .token-pay-currency{
        /* padding: 0 10px !important; */
        border-left: none !important;
      }
  }

  @media only screen and (max-width: 600px) {
    .input-with-hint{
        padding-right: 0px !important;
        /* border-left: none !important; */
        
      }
  }

  
.navLinkActive{
    border-bottom: 2px solid #007bff;
}

@media only screen and (max-width: 600px) {
    .navLinkActive{
        border-bottom: 0px solid #007bff !important;
    }
  }

  .jss1199 {
    padding: 8px;
    border-radius: 8px;
    background-color: #252836;
}
.jss1120{
    padding-top: 8px;
    padding-right: 32px;
    padding-bottom: 8px;
    border-radius: 8px;
    padding-left: 8px;
    background-color: #252836;
}
.jss1204 {
    display: flex;
    align-items: center;
}

.MuiGrid-spacing-xs-2 {
    width: calc(100% + 8px);
    margin: -4px;
}

.MuiGrid-justify-content-xs-space-between {
    justify-content: space-between;
}

.MuiGrid-align-content-xs-center {
    align-content: center;
}

.MuiGrid-align-items-xs-center {
    align-items: center;
}

.MuiGrid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
 
  background-color: #050613;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
/* .accordion:hover,
.active {
  background-color: #050613;
} */

/* Style the accordion content title */
.accordion__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: aliceblue;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: #050613;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
 
}

.form-control:focus {
    color: #fff;
    background-color: #000;
    border-color: #fff;
    outline: 0;
     box-shadow: none !important 
}

.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #fff;
    background-color: #000;
    background-clip: padding-box;
    border: 1px solid #fff;
    /* border-radius: 0.25rem; */
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/*  */
.text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79,70,229,var(--tw-text-opacity));
}
.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(212,212,212,var(--tw-border-opacity));
}
.rounded {
    border-radius: 0.25rem;
}
.w-4 {
    width: 1rem;
}

.opacity-60 {
    opacity: .6;
}
.uppercase {
    text-transform: uppercase;
}
.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}
.text-2xs {
    font-size: .7rem;
}
.font-mono {
    font-family: pixelfont,Helvetica Neue,Helvetica,Arial,sans;
}
/* .pb-1\.5 {
    padding-bottom: 0.375rem;
} */
.pt-2 {
    padding-top: 0.5rem;
}
.cursor-pointer {
    cursor: pointer;
}
.w-full {
    width: 100%;
}
.h-full {
    height: 100%;
}
.ml-2 {
    margin-left: 0.5rem;
}


hr {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    border: 0;
    border-top: 1px solid #383535 !important;
}

.border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255,255,255,var(--tw-border-opacity));
}

/* .galleryinput input[type=checkbox], .galleryinput input[type=radio] {
    --active: #c03540;
    --active-inner: #fff;
    --border: #9f9f9f;
    --border-hover: #000;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 16px;
    width: 17px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc,var(--border));
    background: var(--b,var(--background));
    transition: background .3s,border-color .3s,box-shadow .2s;
} */
/* .modal-content {
    background-color: rgb(187, 189, 183) !important;
} */

.bg-white{
    background-color: #ffffff1a !important;
}
.text-gray{
    color: rgb(187, 189, 183) !important; ;
}
.text-black{
    color: #000 !important;
}
/*  */
.text-xs {
    font-size: .75rem;
    line-height: 0.1rem !important;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.bg-opacity-10 {
    --tw-bg-opacity: 0.1;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255,255,255,var(--tw-bg-opacity));
}
.border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0,0,0,var(--tw-border-opacity));
}
.rounded {
    border-radius: 0.25rem;
}
.items-center {
    align-items: center;
}
.w-full {
    width: 100%;
}
.flex {
    display: flex;
}

.pr-2 {
    padding-right: 0.5rem;
}
.w-8 {
    width: 2rem;
}
/*  */
.opacity-60 {
    opacity: .6;
}
.tracking-wider {
    letter-spacing: .05em;
}
.uppercase {
    text-transform: uppercase;
}
.text-2xs {
    font-size: .7rem;
}
.font-mono {
    font-family: pixelfont,Helvetica Neue,Helvetica,Arial,sans;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.inline-block {
    display: inline-block;
}
.mr-auto {
    margin-right: auto;
}
.pt-px {
    padding-top: 1px;
}

.uppercase {
    text-transform: uppercase;
}
.font-600 {
    font-weight: 600;
}
.text-xs {
    font-size: .75rem;
    line-height: 1rem;
}
.ml-auto {
    margin-left: auto;
}


/*  */
